import { useContext } from 'react';

import API from 'utils/api';
import { windowCheck } from 'utils/helpers';

import WebinarRegistrationContext from 'components/content-blocks/webinar-registration/partials/webinar-registration-context';

import WebinarForm, { WebinarFormValues } from './webinar';

type VeroWebinarSignUpFormProps = {
  onRegistration: () => void
  submitButtonText?: string
  webinarTitle: string
}

const VeroWebinarSignUpForm = (props:VeroWebinarSignUpFormProps) => {

  const webinarRegistrationContext = useContext(WebinarRegistrationContext);

  const submitRegistration = async (data: WebinarFormValues): Promise<void> => {
    if (webinarRegistrationContext && webinarRegistrationContext.mockRegistration) {
      props.onRegistration();
    } else {
      await API.hubspot({
        fullName: data.fullName,
        email: data.emailAddress,
        company: data.companyName,
        webinarPageUrl: windowCheck ? `${window.location.href}?show_replay=true` : '',
        webinarTitle: props.webinarTitle,
      }, 'webinarRecording');
      props.onRegistration();
    }
  };

  return (
    <div className="event-registration-form-container">
      <WebinarForm
        onSubmit={ submitRegistration }
        submitText={ props.submitButtonText ?? 'Register to Watch' }
      />
    </div>
  );
};

export default VeroWebinarSignUpForm;
